import Axios from 'axios';
export default class Productos {
    static buscarProductos(){
        return new Promise((resolve, reject)=>{
            Axios.get('productos').then((e)=>{
                return resolve(e.data.productos)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static getProducto(id){
        return new Promise((resolve, reject)=>{
            Axios.get('productos/'+id).then((e)=>{
                return resolve(e.data.producto)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static guardar(producto){
        return new Promise((resolve, reject)=>{
            Axios.post('productos', producto).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static editar(producto){
        return new Promise((resolve, reject)=>{
            Axios.put('productos/'+producto.id, producto).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static eliminar(producto){
        return new Promise((resolve, reject)=>{
            Axios.delete('productos/'+producto.id).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}