import { createWebHistory, createRouter } from "vue-router";
import Login from '@/components/Login'
import Home from '@/components/Home'

//clientes
import ListarClientes from '@/components/clientes/ListarClientes'
import AgregarCliente from '@/components/clientes/AgregarCliente'
import VerCliente from '@/components/clientes/VerCliente'

//listas
import AgregarLista from '@/components/listas/AgregarLista'
import ListarListas from '@/components/listas/ListarListas'
import VerLista from '@/components/listas/VerLista'

//pedidos
import AgregarPedidos from '@/components/pedidos/AgregarPedidos'
import ListarPedidos from '@/components/pedidos/ListarPedidos'
import VerPedidos from '@/components/pedidos/VerPedidos'

//productos
import AgregarProducto from '@/components/productos/AgregarProducto'
import ListarProductos from '@/components/productos/ListarProductos'
import VerProducto from '@/components/productos/VerProducto'

import store from '../store'

const cliente = [
    {
        path: "/clientes/lista",
        name:"ListarClientes",
        component: ListarClientes,
        meta: {
            private:true,
        },
    },
    {
        path: "/clientes/agregar",
        name:"AgregarCliente",
        component: AgregarCliente,
        meta: {
            private:true,
        },
    },
    {
        path: "/cliente/ver/:id",
        name:"VerCliente",
        component: VerCliente,
        meta: {
            private:true,
        },
    },
  
];
const lista = [
    {
        path: "/lista-precios/lista",
		name:"ListarListas",
		component: ListarListas,
        meta: {
            private:true,
        },
    },
	{
        path: "/lista-precios/agregar",
		name:"AgregarLista",
		component: AgregarLista,
        meta: {
            private:true,
        },
    },
	{
        path: "/lista-precios/ver/:id",
		name:"VerLista",
		component: VerLista,
        meta: {
            private:true,
        },
    }
    
];
const login = [
    {
        path: "",
		name:"Login",
		component: Login,
    },
    
];
const pedido = [
    {
        path: "/pedidos/agregar",
        name:"AgregarPedidos",
        component: AgregarPedidos,
        meta: {
            private:true,
        },
    },
    {
        path: "/pedidos",
        name:"ListarPedidos",
        component: ListarPedidos,
        meta: {
            private:true,
        },
    },
    {
        path: "/pedidos/ver/:id",
        name:"VerPedido",
        component: VerPedidos,
        meta: {
            private:true,
        },
    }
]
const producto = [
    {
        path: "/producto/agregar",
        name:"AgregarProducto",
        component: AgregarProducto,
        meta: {
            private:true,
        },
    },
    {
        path: "/producto/listar",
        name:"ListarProductos",
        component: ListarProductos,
        meta: {
            private:true,
        },
    },
    {
        path: "/producto/ver/:id",
        name:"VerProducto",
        component: VerProducto,
        meta: {
            private:true,
        },
    }
];
const home = [
    {
        path: "/inicio",
        name:"Home",
        component: Home,
        meta: {
            private:true,
        },
        children:[
            ...cliente,
            ...lista,
            ...pedido,
            ...producto
        ]
    },
  
];
const routes = [
    ...login,
    ...home,
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async (to, from, next) => {
    //const store = useStore();
    if(to.path== "/" && localStorage.getItem('token')) {
        next({name:"Home"})
    }else if(to.matched.length == 0){
        next({name: 'Login'})
    }
    else if(to.meta.private){
        await store.dispatch("getUser", localStorage.getItem("token"))
        if(store.state.user){
            next();
        }else{
            next({name: 'Login'})
        }
    }
    else{
        next();
    }
})
  
  export default router