<template>
    <CardPrimary title="Listas de precios" :addBtn="{type:'button', route:null}" claseBtn="btn btn-outline-primary" buttonText="Nueva lista" iconBtn="fa fa-list pr-2" @btnclick="router.push({name: 'AgregarLista'})">
        <template v-slot:container>
            <CardSecondary title="Mis listas de precios" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                        <div class="hovereable card-post--1 col-12 shadow-none row border-bottom mb-3" v-for="(lista, i) in listas" :key="i">
                            <div class="col-12 pt-3 mb-3 text-muted" >
                                <div class="row" @click="verLista(lista.id)" role="button">
                                    <span class="col-12 col-sm-12 pb-2 row text-info pr-0">
                                        <b class="col-sm-11 col-11">{{lista.nombre}}</b>
                                    </span>                        
                                    <span class="col-12 col-sm-5"><small>Fecha creación: </small><b>{{$filters.formatDate(lista.created_at, "DD-MM-YYYY")}}</b></span>                        
                                </div>                                
                            </div>                            
                        </div>
                        <div v-if="listas.length == 0">
                            <p class="text-center py-3 text-muted">No se encontraron listas creadas...</p>
                        </div>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import { ref, onMounted } from 'vue'
import Axios from 'axios';
import { useRouter } from 'vue-router'
export default {
    name:"ListarListas",
    components:{
        CardPrimary,
        CardSecondary
    },
    setup () {
        const listas = ref([]);
        const router = useRouter();
        onMounted(async() => {
            await Axios.get('listas').then((e)=>{
                listas.value= e.data.listas
            })
        })
        const verLista = (id)=>{
            router.push({name: 'VerLista',params:{id:id}})
        }
        
        return { listas, verLista, router }
    }
}
</script>