<template>
    <CardPrimary title="Producto">
        <template v-slot:container>
            <CardSecondary title="Modificar Producto" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="col-12 row">
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="producto.descripcion" placeholder="Descripción" class="form-control" :class="data.enviado && v$.producto.descripcion.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Descripción</label>
                            <div class="text-danger" v-if="data.enviado && v$.producto.descripcion.$error">
                                Debe ingresar la Descripción
                            </div>
                        </div>
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="producto.cantidad" placeholder="Apellido" class="form-control" :class="data.enviado && v$.producto.cantidad.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Cantidad</label>
                            <div class="text-danger" v-if="data.enviado && v$.producto.cantidad.$error">
                                Debe ingresar una Cantidad
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-3 text-end">
                        <button class="btn btn-danger mr-4" @click="eliminar">Eliminar</button>
                        <button class="btn btn-outline-primary" @click="guardar">Guardar</button>
                    </div>
                </template>
                <template v-slot:footer>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { ref, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Productos from './productos'
import Alert from '@/components/utils/alert'
export default {
    name:"VerProducto",
    components:{
                CardPrimary,
                CardSecondary
    },
    setup () {
        const route = useRoute()
        const router = useRouter();

        const producto = ref({descripcion:null, cantidad:null})

        let data = reactive({
                enviado: false,   
                error: false        
        })
        onMounted(async()=>{
            await Productos.getProducto(route.params.id).then((e)=>{
                producto.value = e
            }).catch(()=>{
                Alert.Error({text: "No se encontro el producto"}).then(()=>{
                    router.push({name: 'ListarProductos'})
                })
            })
        })
        const v$ = useVuelidate()

        const guardar = async()=>{
            await Productos.editar(producto.value).then((e)=>{
                if(e.data.success){
                    Alert.Success({text: `Se modifico ${producto.value.descripcion}. `}).then(()=>{
                        router.push({name: 'ListarProductos'})
                    })
                }
            }).catch(()=>{
                Alert.Error({text: "Ocurrió un error al intentar guardar"})
            })
        }

        const eliminar = ()=>{
            Alert.Option({titles:"¿Desea eliminar este producto?",cancelButtonText:"Cancelar", text:"Se borrara de todas las listas donde este incluido."}).then(async(e)=>{
                if(e.isConfirmed){
                     await Productos.eliminar(producto.value).then((e)=>{
                        if(e.data.success){
                            Alert.Success({text:`Se eliminó ${producto.value.descripcion}. `})
                            .then(()=>{
                                router.push({name: 'ListarProductos'})
                            })
                        }
                    }).catch(()=>{
                        Alert.Error({text: "Ocurrió un error al intentar eliminar"})
                    })
                }
            })
        }

        return { v$ , producto, data, guardar, eliminar }
    },
    validations(){    
        return {
            producto:{
                descripcion: { required },
                cantidad: { required }
            }
        }   
    }
}
</script>