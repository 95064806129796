<template>
    <CardPrimary title="Lista de precios">
        <template v-slot:container>
            <CardSecondary title="Modificar Lista" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="col-12 row">
                        <div class="form-floating form-group col-sm-12 col-12">
                            <input type="text" v-model="lista.nombre" placeholder="Nombre" class="form-control" :class="data.enviado && v$.lista.nombre.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Nombre de Lista</label>
                            <div class="text-danger" v-if="data.enviado && v$.lista.nombre.$error">
                                Debe ingresar un Nombre
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                </template>
            </CardSecondary>
            <CardSecondary title="Agregar productos" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="col-12 listas">
                        <div class="form-floating form-group div1">
                            <SelectSearch ref="selectSearch" url="productos" palceholder="Seleccione el producto" campo="descripcion" @retorno="producto.id=$event.id, producto.descripcion=$event.descripcion" :clase="data.enviado && v$.producto.descripcion.$error ? 'is-invalid' : ''">
                                <label for="floatingInput">Seleccione el producto</label>
                                <div class="text-danger" v-if="data.enviado && v$.producto.descripcion.$error">
                                    Debe buscar un producto
                                </div>
                            </SelectSearch>
                        </div>
                        <div class="form-group div2 form-floating">
                            <input type="text" placeholder="Ingrese el precio" v-model="producto.precio" class="form-control" :class="data.enviado && v$.producto.precio.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Ingrese el precio</label>
                            <div class="text-danger" v-if="data.enviado && v$.producto.precio.$error">
                                Debe ingresar un Precio
                            </div>
                        </div>
                        <div class="form-group div1 d-flex justify-content-center align-items-end">
                            <button class="btn text-primary" @click="generarLista"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                </template>
            </CardSecondary>
            <CardSecondary :title="'Confirmar Lista '+lista.nombre" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="row col-12">
                        <div class="hovereable card-post--1 col-12 shadow-none row border-bottom mb-3" v-for="(listaProducto, i) in listaProductos" :key="i">
                            <div class="col-12 pt-1 mb-1 text-muted">
                                <div class="row">
                                    <span class="col-12 col-sm-12 pb-0 row text-info pr-0">
                                        <b class="col-sm-11 col-10">{{listaProducto.descripcion}}</b>
                                        <span class="col-2 col-sm-1"><button @click="quitar(listaProducto)" class="btn text-danger"><i class="fa fa-times"></i></button></span>                        
                                    </span>                     
                                    <span class="col-12 col-sm-5">Precio: $<b>{{listaProducto.precio}}</b></span>                        
                                </div>   
                            </div>                            
                        </div>
                        <div v-if="listaProductos.length <= 0">
                            <p class="text-muted">No se han incluido productos</p>
                        </div>
                        <div class="col-12 py-3 d-flex px-0 justify-content-between justify-content-sm-end">
                            <button class="btn btn-danger mr-sm-5 mr-2" @click="deleteLista">Eliminar Lista</button>
                            <button class="btn btn-outline-primary" @click="confirmarLista">Confirmar</button>
                        </div>
                    </div>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { reactive, ref, onMounted } from 'vue'
import SelectSearch from '@/components/layouts/SelectSearch'
import Alert from '@/components/utils/alert'
import { useRouter, useRoute } from 'vue-router'
import Lista from './lista'
export default {
    name:"AgregarLista",
    components:{
        CardPrimary,
        CardSecondary,
        SelectSearch
    },
    setup () {
        
        const router = useRouter();
        const route = useRoute();
        let producto = reactive({
                descripcion: null,
                precio: null,
                id:null
        })
        let lista = reactive({
            nombre: '',
            id: route.params.id,
        })
        let data = reactive({
                enviado: false,   
                error: false        
        })
        const listaProductos = ref([]);
        const selectSearch = ref(null)

        const v$ = useVuelidate();

        onMounted(async()=>{
            await Lista.getLista(lista.id).then((e)=>{
                lista.nombre = e.lista.nombre
                listaProductos.value = e.lista.productos
            }).catch(()=>{
                Alert.Error({text: `Ocurrió un error al intentar guardar`})
            })
        })

        const generarLista = ()=>{
            data.enviado = true;
            data.error = false
            v$.value.producto.$touch();
            console.log(v$.value.producto)
            if (v$.value.producto.$invalid) {
                return false
            }
            data.enviado = false;
            listaProductos.value.push({...producto})
            selectSearch.value.search = null
            producto.descripcion = null
            producto.precio = null
            producto.id = null            
        }

        const confirmarLista = ()=>{
            data.enviado = true;
            data.error = false
            v$.value.lista.$touch();
            if (v$.value.lista.$invalid) {
              return false
            }
            data.enviado = false,
            Lista.update(lista, listaProductos).then((e)=>{
                if(e.data.success){
                    Alert.Success({text: `Se guardo la lista con éxito.`}).then(()=>{
                        router.push({name: 'ListarListas'})
                    })
                }else{
                    Alert.Error({text: `Ocurrió un error al intentar guardar`})
                }
            }).cath(()=>{
                Alert.Error({text: `Ocurrió un error al intentar guardar`})
            })
        }
        const deleteLista = () =>{
            Alert.Option({cancelButtonText:"Cancelar", text:"¿Esta seguro que desea eliminar esta lista?"}).then(async (e)=>{
                if(e.isConfirmed){
                    await Lista.delete(lista).then((e)=>{
                        if(e.data.success){
                            Alert.Success({text: `Se eliminó la lista con éxito.`}).then(()=>{
                                router.push({name: 'ListarListas'})
                        })
                    }else{
                        Alert.Error({text: `Ocurrió un error al intentar eliminar`})
                    }
                    }).catch(()=>{
                        Alert.Error({text: `Ocurrió un error al intentar eliminar`})
                    })
                }
            })
        }
        const quitar = (producto)=>{
            listaProductos.value = listaProductos.value.filter((e)=> e.id !== producto.id)
            Alert.Success({text: "El producto se quito con éxito. Debe confirmar la lista para que los cambios tengan efecto."})
        }
        return { v$, data, producto, generarLista, lista, listaProductos, confirmarLista, deleteLista, quitar, selectSearch }
    },
    validations(){
        return {
            lista:{
                nombre: { required },
            },
            producto:{
                descripcion: { required },
                precio: { required }
            }
        } 
    }
}
</script>