<template>
    <CardPrimary title="Productos">
        <template v-slot:container>
            <CardSecondary title="Agregar Producto" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="col-12 row">
                        <div class="form-floating form-group col-sm-9 col-12">
                            <input type="text" v-model="producto.descripcion" placeholder="descripción" class="form-control" :class="data.enviado && v$.producto.descripcion.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Descripción</label>
                            <div class="text-danger" v-if="data.enviado && v$.producto.descripcion.$error">
                                Debe ingresar un Descripción
                            </div>
                        </div>
                        <div class="form-floating form-group col-sm-3 col-12">
                            <input type="text" v-model="producto.cantidad" placeholder="descripción" class="form-control" :class="data.enviado && v$.producto.cantidad.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Cantidad</label>
                            <div class="text-danger" v-if="data.enviado && v$.producto.cantidad.$error">
                                Debe ingresar un Cantidad
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-3 text-end">
                        <button class="btn btn-outline-primary" @click="guardar">Guardar</button>
                    </div>
                </template>
                <template v-slot:footer>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import Alert from '@/components/utils/alert'
import Productos from './productos'
export default {
    name:"ListarUsers",
    components:{
                CardPrimary,
                CardSecondary
    },
    setup () {
        const router = useRouter();

        let producto = reactive({
                descripcion: null,
                cantidad: 0      
        })
        let data = reactive({
                enviado: false,   
                error: false        
        })
        const v$ = useVuelidate();
        const guardar = ()=>{
            console.log(v$.value.producto)
            data.enviado = true;
            data.error = false
            v$.value.producto.$touch();
            if (v$.value.producto.$invalid) {
              return false
            }
            Productos.guardar(producto).then((e)=>{
                if(e.data.success){
                    Alert.Success({text: `Se guardo el producto con exito.`}).then(()=>{
                        router.push({name: 'ListarProductos'})
                    })
                }else{
                     Alert.Error({text: "Ocurrió un error al intentar guardar"})
                }

            }).catch(()=>{
                 Alert.Error({text: "Ocurrió un error al intentar guardar"})
            })
        }
        return { v$, producto, data, guardar }

    },
    validations(){
        return {
            producto:{
                descripcion: { required },
                cantidad: { required },
            }
        } 
    }
}
</script>