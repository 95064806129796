<template>
    <CardPrimary title="Clientes" :addBtn="{type:'button', route:null}" claseBtn="btn btn-outline-primary" buttonText="Nuevo Cliente" iconBtn="fa fa-user pr-2" @btnclick="router.push({name: 'AgregarCliente'})">
        <template v-slot:container>
            <CardSecondary title="Mis Clientes" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="form-floating mb-3 px-0">
                        <input type="text" v-model="search" id="search" @input="search = $event.target.value" class="form-control" placeholder="Buscar..." />
                        <label for="search">Buscar...</label>
                    </div>
                    <div class="hovereable card-post--1 col-12 shadow-none row border-bottom mb-3 p-0 mx-sm-0" v-for="(cliente, i) in clientesFiltrados" :key="i">
                        <div class="col-12 pt-3 mb-3 text-muted" >
                            <div class="row" @click="verCliente(cliente.id)" role="button">
                                <span class="col-12 col-sm-12 pb-2 row text-info pr-0">
                                    <b class="col-sm-11 col-11">{{cliente.full_name}}</b>
                                </span>                        
                                <span class="col-12 col-sm-3"><small>Cuit: </small><b>{{cliente.cuit}}</b></span>                        
                                <span class="col-12 col-sm-6"><small>Email: </small><b>{{cliente.email}}</b></span>                        
                            </div>                                
                        </div>                            
                    </div>
                    <div v-if="clientes.length == 0">
                        <p class="text-center py-3 text-muted">No se encontraron clientes...</p>
                    </div>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import { ref, onMounted, computed } from 'vue'
import Axios from 'axios';
import { useRouter } from 'vue-router'
export default {
    name:"ListarUsers",
    components:{
        CardPrimary,
        CardSecondary
    },
    setup () {
        const clientes = ref([]);
        const router = useRouter();
        const search = ref();

        onMounted(async() => {
            await Axios.get('clientes').then((e)=>{
                clientes.value= e.data.clientes
            })
        })
        const verCliente = (id)=>{
            router.push({name: 'VerCliente',params:{id:id}})
        }
        const clientesFiltrados = computed(()=>{
            if(search.value)
                return clientes.value.filter((e)=>e.full_name.toLowerCase().includes(search.value.toLowerCase()))
            else  
                return clientes.value
        }) 
        
        return { clientes, verCliente, router, clientesFiltrados, search }
    }
}
</script>