<template>
    <CardPrimary title="Productos" :addBtn="{type:'button', route:null}" claseBtn="btn btn-outline-primary" buttonText="Nuevo Producto" iconBtn="fa fa-tag pr-2" @btnclick="router.push({name: 'AgregarProducto'})">
        <template v-slot:container>
            <CardSecondary title="Listado de productos" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="form-floating mb-3 px-0">
                        <input type="text" v-model="search" id="search" @input="search = $event.target.value" class="form-control" placeholder="Buscar..." />
                        <label for="search">Buscar...</label>
                    </div>
                    <div class="hovereable card-post--1 col-12 shadow-none row border-bottom mb-3 px-sm-0 mx-sm-0" v-for="(producto, i) in productosFiltrados" :key="i">
                        <div class="col-12 pt-3 mb-3 text-muted" >
                            <div class="row" @click="verProducto(producto.id)" role="button">
                                <span class="col-12 col-sm-12 pb-2 row text-info pr-0">
                                    <b class="col-sm-11 col-11">{{producto.descripcion}}</b>
                                </span>                        
                                <span class="col-12 col-sm-3"><small>Stock: </small><b>{{producto.cantidad}}</b></span>                        
                            </div>                                
                        </div>                            
                    </div>
                    <div v-if="productos.length == 0">
                        <p class="text-center py-3 text-muted">No se encontraron productos...</p>
                    </div>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import Productos from '@/components/productos/productos'
export default {
    name:"ListarProductos",
    components:{
        CardPrimary,
        CardSecondary
    },
    setup () {
        const productos = ref([]);
        const router = useRouter();
        const search = ref();
        onMounted(async() => {
            productos.value= await Productos.buscarProductos()
        })
        const verProducto = (id)=>{
            router.push({name: 'VerProducto',params:{id:id}})
        }
        const productosFiltrados = computed(()=>{
            if(search.value)
                return productos.value.filter((e)=>e.descripcion.toLowerCase().includes(search.value.toLowerCase()))
            else  
                return productos.value
        }) 

        
        return { productos, verProducto, router, search, productosFiltrados }
    }
}
</script>