<template>
	<div class="no-gutters cont1">
		<Loader />
		<div class="position-relative col-12 px-0">
			<Navbar1 classNav="clase-grid" @logout="logout($event)">
				<template v-slot:items>
					<li class="nav-item border-right dropdown notifications d-flex align-items-center col-sm-11">
                        <div class="col-12 col-sm-2">
							<h4 class="col-12">Cigarrillos</h4>
							
                        </div>
                    </li>
				</template>
				<template v-slot:itemDropdown1>
                        <a class="dropdown-item">
                            <div class="notification__icon-wrapper">
                                <div class="notification__icon">
                                    <i class="fa fa-home"></i>
                                </div>
                            </div>
                            <div class="notification__content">
                                <h5 class="text-secondary">Usuario sleccionado: </h5>
								<p class="text-secondary" >{{store.state.user ? store.state.user.name : '-'}}</p>
                            </div>
                        </a>
                </template>
				<template v-slot:subItemDropdown1>
                        <button class="hovereable btn btn-default text-danger" @click="logout" ><i class="fa fa-sign-out text-danger"></i>Logout</button>
				</template>
                
				
			</Navbar1>
		</div>
		<div class="col-12 d-sm-flex vh-100">
			<div class="col-lg-2 col-9 sidebar pl-0 h-100">
				<Sidebar title="Menu">
					<template v-slot:newRoutes>
						<li class="nav-item">
							<span class="nav-link collapsible" role="button"><i class="fa fa-shopping-cart"></i> Pedidos </span>
							<div class="expanded hidden ml-2">
								<li class="nav-item">
									<router-link class="nav-link text-dark a" :to="{ name: 'ListarPedidos' }">
										<i class="fa fa-shopping-cart"></i> Ver Pedidos
									</router-link>
								</li>
								<li class="nav-item">
									<router-link class="nav-link text-dark a" :to="{ name: 'AgregarPedidos' }">
										<i class="fa fa-shopping-cart"></i> Agregar
									</router-link>
								</li>
							</div>                         
						</li>
						<li class="nav-item">
							<span class="nav-link collapsible" role="button"><i class="fa fa-users"></i> Clientes </span>
							<div class="expanded hidden ml-2">
								<li class="nav-item">
									<router-link class="nav-link text-dark a" :to="{ name: 'ListarClientes' }">
										<i class="fa fa-users"></i> Ver Clientes
									</router-link>
								</li>
								<li class="nav-item">
									<router-link class="nav-link text-dark a" :to="{ name: 'AgregarCliente' }">
										<i class="fa fa-users"></i> Nuevo Cliente
									</router-link>
								</li>
							</div>                         
						</li>
						<li class="nav-item">
							<span class="nav-link collapsible" role="button"><i class="fa fa-list"></i> Listas </span>
							<div class="expanded hidden ml-2">
								<li class="nav-item">
									<router-link class="nav-link text-dark a" :to="{ name: 'ListarListas' }">
										<i class="fa fa-list"></i> Ver Listas
									</router-link>
								</li>
								<li class="nav-item">
									<router-link class="nav-link text-dark a" :to="{ name: 'AgregarLista' }">
										<i class="fa fa-list"></i> Cargar Listas
									</router-link>
								</li>
							</div>                         
						</li>
						<li class="nav-item">
							<span class="nav-link collapsible" role="button"><i class="fa fa-tags"></i> Productos </span>
							<div class="expanded hidden ml-2">
								<li class="nav-item">
									<router-link class="nav-link text-dark a" :to="{ name: 'AgregarProducto' }">
										<i class="fa fa-tag"></i> Agregar Producto                                
									</router-link>
								</li>
								<li class="nav-item">
									<router-link class="nav-link text-dark a" :to="{ name: 'ListarProductos' }">
										<i class="fa fa-tags"></i> Listado Productos
									</router-link>
								</li>
							</div>                         
						</li>
					</template>
				</Sidebar>
			</div>
			<div class="cont2 mt-3 px-sm-5 px-3 col-sm-10 col-12">
				<transition name="fade" mode="out-in">
					<router-view></router-view>
				</transition>
			</div>
		</div>
  </div>
</template>
<style>
	.expanded li{
        background-color: #203239!important;
        box-shadow: inset 0.1875rem 0 0 #203239;
		color:#EEEDDE!important
    }
	.expanded li .nav-link i{
        color: #EEEDDE!important;
    }
	.expanded li .nav-link{
        color: #EEEDDE!important;
    }
	.main-sidebar{
		background-color: #141E27;
	}
	.main-sidebar .nav-item span{
		color: #EEEDDE!important;
	}
	.main-sidebar .nav-item .nav-link:hover{
		background-color: #203239!important;
		color: #EEEDDE!important;
	}
	.main-sidebar .nav-item .nav-link:hover i{
		background-color: #203239!important;
		color: #EEEDDE!important;
	}
	.sidebar .nav-item:hover{
		background-color: #141E27!important;
		color: #EEEDDE!important;
	}
	.sidebar .nav-item:hover i{
		background-color: transparent!important;
		color: #EEEDDE!important;
	}
	nav{
		background-color: #141E27;
	}
	nav h4{
		color: #EEEDDE;
	}
	nav .dropdown-menu{
		background-color: #EEEDDE;
	}
	nav li .dropdown-toggle{
		color: #EEEDDE;
	}
	nav li a, .navbar-light .navbar-toggler-icon{
		background-image: url('/hambur.png')!important
	}
	.cont2{
		background-color: #EEEDDE;
	}
	.cont1{
		background-color: #EEEDDE;
	}
	.bg-light.card .card-title, 
	.bg-light.card .card-body, 
	.bg-light.card .card-header, 
	.bg-light.card .card-footer,
	.card .bg-white, .card input, 
	.card select{
		background-color: #f9f9f8!important;
	}
	.hidden{
        display: none;
    }
	/* .card .bg-white, .card input{
		background-color: #a7a717!important;
	} */
	/* .card input{
		background-color: #ecece5!important;
	} */
</style>
<script>
import Navbar1 from 'plantilla-share/src/lib-components/Navbar'
import Sidebar from 'plantilla-share/src/lib-components/Sidebar'
import { useStore } from 'vuex'
import 'plantilla-share/node_modules/bootstrap/dist/js/bootstrap.js'
import Loader from '@/components/layouts/Loader'
import { onMounted} from 'vue'
import 'bootstrap'
// import '@/assets/style.css'
import { useRouter } from 'vue-router'
export default {
	name: 'HelloWorld',
	components:{
		Navbar1,
		Sidebar,
		Loader
	},
	setup () {
		const router = useRouter();
        const store = useStore();
		
		const logout = ()=>{
			console.log("algo")
			store.dispatch('logout').then((res)=>{
				console.log(res)
				router.push({name:"Login"});
				}   
			)
		}
		onMounted(()=>{
			require('bootstrap');
			require('bootstrap/dist/css/bootstrap.css');
			require('plantilla-share/src/font-awesome/css/font-awesome.css');
			require('plantilla-share/src/lib-components/shards-dashboards.1.1.0.css');
			require('@/assets/style.css');
		})
		
        return{logout, store}
    },
	created(){
	}
	
	}
</script>
<style>
.clase-grid ul{
	width: 100%;
}
</style>

