<template>
    <CardPrimary title="Clientes">
        <template v-slot:container>
            <CardSecondary title="Modificar Cliente" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="col-12 row">
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="cliente.nombre" placeholder="Nombre" class="form-control" :class="data.enviado && v$.cliente.nombre.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Nombre</label>
                            <div class="text-danger" v-if="data.enviado && v$.cliente.nombre.$error">
                                Debe ingresar un Nombre
                            </div>
                        </div>
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="cliente.apellido" placeholder="Apellido" class="form-control" :class="data.enviado && v$.cliente.apellido.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Apellido</label>
                            <div class="text-danger" v-if="data.enviado && v$.cliente.apellido.$error">
                                Debe ingresar un Apellido
                            </div>
                        </div>
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="cliente.cuit" placeholder="cuit" class="form-control" :class="data.enviado && v$.cliente.cuit.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">CUIT</label>
                            <div class="text-danger" v-if="data.enviado && v$.cliente.cuit.$error">
                                Debe ingresar un CUIT
                            </div>
                        </div>
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="cliente.email" placeholder="Email" class="form-control" :class="data.enviado && v$.cliente.email.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Email</label>
                            <div class="text-danger" v-if="data.enviado && v$.cliente.email.$error">
                                Debe ingresar un Email
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-3 text-end">
                        <button class="btn btn-danger me-5" @click="eliminar">Eliminar</button>
                        <button class="btn btn-outline-primary" @click="guardar">Guardar</button>
                    </div>
                </template>
                <template v-slot:footer>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import Axios from 'axios';
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/utils/alert'
import Cliente from './cliente'
export default {
    name:"VerCliente",
    components:{
                CardPrimary,
                CardSecondary
    },
    setup () {
        const route = useRoute()
        const router = useRouter();

        const cliente = reactive({
                nombre: null,
                apellido: null,
                email: null,
                cuit: null,
                id: route.params.id   
        })
        let data = reactive({
                enviado: false,   
                error: false        
        })
        onMounted(async()=>{
            await Axios.get('clientes/'+cliente.id).then((e)=>{
                cliente.nombre = e.data.cliente.nombre
                cliente.apellido = e.data.cliente.apellido
                cliente.email = e.data.cliente.email
                cliente.cuit = e.data.cliente.cuit
            })
        })
        const v$ = useVuelidate()
        const guardar = ()=>{
            data.enviado = true;
            data.error = false
            v$.value.cliente.$touch();
            if (v$.value.cliente.$invalid) {
              return false
            }
            Axios.put('clientes/'+cliente.id, cliente).then((e)=>{
                if(e.data.success){
                    Alert.Success({text: `Se modifico ${cliente.nombre} ${cliente.apellido}. `}).then(()=>{
                        router.push({name: 'ListarClientes'})
                    })
                }else{
                    Alert.Error({text: "Ocurrió un error al intentar guardar."})
                }
            }).catch(()=>{
               Alert.Error({text: "Ocurrió un error al intentar guardar."})
            })
        }
        const eliminar = ()=>{
            Alert.Option({titles:"¿Desea eliminar este cliente?", text:"No podás desacer esta acción."}).then((e)=>{
                if(e.isConfirmed){
                    Cliente.Delete(cliente).then((e)=>{
                        if(e.data.success){
                            Alert.Success({text: "Se eliminó con exito el cliente."}).then(()=>{
                                router.push({name: 'ListarClientes'})
                            })
                        }else{
                            Alert.Error({text: "Ocurrió un error al intentar eliminar"})
                        }
                    }).catch(()=>{
                        Alert.Error({text: "Ocurrió un error al intentar eliminar."})
                    })
                }
            })
        }

        return { v$ , cliente, data, guardar, eliminar }
    },
    validations(){    
        return {
            cliente:{
                nombre: { required },
                apellido: { required },
                email: { required },
                cuit: { required }
            }
        }   
    }
}
</script>