import Swal from 'sweetalert2';

export default class Alert{
    static Option({titles="¡Atención!", 
            text="", 
            html="", 
            icon="warning", 
            cancelButtonText="Cancel", 
            cancelButtonColor="#343a40",
            confirmButtonColor="#d33",
            allowOutsideClick= true,
            confirmButtonText="Si, Estoy seguro",
            }){
        return Swal.fire({
            title: titles,
            text: text,
            html: html,
            allowOutsideClick: allowOutsideClick,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
        })
    }

    static Success({text="La operación se realizó con exito"}){
        return Swal.fire({
            title:'Exito...',
            text:  text, 
            icon:'success'
        })
    }
    static Error({text="Ocurrió un error"}){
        return Swal.fire({
            title:'Opsss...',
            text: text, 
            icon:'error'
        })
    }
}