import Axios from 'axios';
export default class Lista{
    static getLista(id){
        return new Promise((resolve, reject)=>{
            Axios.get('listas/'+id).then((e)=>{
                return resolve(e.data)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static getListas(){
        return new Promise((resolve, reject)=>{
            Axios.get('listas').then((e)=>{
                return resolve(e.data.listas)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static update(lista, listaProductos){
        return new Promise((resolve, reject)=>{
            Axios.put('listas/'+lista.id, {productos:listaProductos.value, lista}).then((e)=>{
                return resolve(e)
            }).catch(()=>{
                return reject(null)
            })
        })
    }

    static delete(lista){
        return new Promise((resolve, reject)=>{
            Axios.delete('listas/'+lista.id).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}