<template>
    <div class="divcont form-floating" @click="eventoOcultarMostrar()" autocomplete="off">
        <input autocomplete="off" @focus="getSelector()" type="text" id="btnSearch" class="form-control" :value="search" @input="search = $event.target.value" :class="clase" :placeholder="placeholder">
        <div class="spinner-border loader d-none" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <slot></slot>
        <ul class="ul d-none scroll">
            <li v-for="(dato, i) in datos" :key="i" @click="seleccionado(dato)">
                <div>
                    <p class="my-0">{{dato[campo]}}</p>
                    <span style="font-size:80%" v-for="(campo, index) in subCampo" :key="index" :class="'mr-2 '+campo.clase">{{campo.nombre}} {{dato[campo.key]}}</span>
                </div>
            </li>
            <li v-if="datos.length <= 0">
                <p>No hay datos...</p>
            </li>            
        </ul>
    </div>
</template>
<script>
import axios from "axios";
import $ from 'jquery';
export default {
    name:'SelectSearch',
    data(){
        return{
            search:"",
            datos:[],
            tiempo:null,
            selector : null,
        }
    },
    props:{
        url:String,
        campo:String,
        subCampo:{},
        placeholder:String,
        autoCompletar:String,
        clase: String
        
    },
    created(){
        //this.getData()
    },
    mounted(){
        $(document).on("click", function(){
            $(".divcont").find("ul").addClass("d-none");
        })
    },
    unmounted(){
        if(this.selector != null)
            this.selector.removeEventListener("click", this.eventoOcultarMostrar)
    },
    updated(){

    },
    watch:{
        search: function(){
            clearTimeout(this.tiempo);
            this.tiempo = setTimeout(()=>{
                    this.getData();
                    if(this.selector)
                        if(this.selector.querySelector(".ul") != null)
                            this.selector.querySelector(".ul").classList.remove("d-none")
            }, 1000);
        }
    },
    methods:{
        getSelector(){
            this.selector = event.target.closest("div");
        },
        eventoOcultarMostrar(){
            this.selector = event.target.closest("div");
            let id = event.target.getAttribute("id")
            if(id == "btnSearch"){
                this.selector.querySelector(".ul").classList.toggle("d-none")
                  
            }else{
                if(this.selector.querySelector(".ul"))
                this.selector.querySelector(".ul").classList.add("d-none")
            }
        },
        getData(){
            if(this.selector){
                this.selector.querySelector(".loader")?.classList.add("d-block")
                this.selector.querySelector(".loader")?.classList.remove("d-none")
            }
            axios.get(this.url, {params: {search:this.search, select:true }},)
            .then( res => {
                if (res.data && res.data.length > 0) {
                    this.datos = res.data
                }else{
                    this.$emit("retorno", null)
                }
                if(this.selector.querySelector(".loader")){
                    this.selector.querySelector(".loader").classList.add("d-none")
                    this.selector.querySelector(".loader").classList.remove("d-block")
                }
            }).catch( ()=>{
                this.datos = []
                if(this.selector){
                    this.selector.querySelector(".loader").classList.add("d-none")
                    this.selector.querySelector(".loader").classList.remove("d-block")
                }
            })
        },
        seleccionado(data){
            this.search = data[this.campo]
            this.$emit("retorno", data)
        },
    }
}
</script>
<style scoped>
    .ul{
        list-style: none;
        overflow-y: scroll;
        max-height: 150px;
        background-color:white;
        padding: 10px;
        position: absolute;
        width: 100%;
        box-shadow: 0px 1px 3px -1px;
        cursor: pointer;
        z-index: 15;
    }
    li div:hover{
        background-color: #e2e2e2;
        margin: 0;
        padding: 10px;
    }
    li div{
        margin: 0;
        padding: 10px;
    }
    .divcont{
        position: relative!important;
        height: 100%;
    }
    .contenedor {
        margin: 2rem auto;
        border: 1px solid #aaa;
        height: 300px;
        width:90%;
        max-width: 400px;
        background: #f1f2f3;
        overflow:auto;
        box-sizing: border-box;
        padding:0 1rem;
    }
    .loader{
        position: absolute;
        top: 21px;
        right: 9px;
    }
/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
    .scroll{
        z-index: 2000;
    }
    .ul::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .ul::-webkit-scrollbar:vertical {
        width:10px;
    }

    .ul::-webkit-scrollbar-button:increment,.ul::-webkit-scrollbar-button {
        display: none;
    } 

    .ul::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    .ul::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 2px solid #f1f2f3;
    }

    .ul::-webkit-scrollbar-track {
        border-radius: 10px;  
    }
    #btnSearch{
        color:black;
        font-weight: 500;        
    }
</style>