import { createStore } from 'vuex'
import axios from 'axios';
export default createStore({

    state:{
        user: null,
        token: null,
        errorLogin:false
    },
    mutations:{
        //solo para modificar el state
        setUser(state, payload){
            state.user = payload
        },
        setToken(state, payload){
            state.token = payload
        },
        setRes(state, payload){
            state.error = payload
        }
    },
    actions:{
        async login({ dispatch, commit }, credentials) {
            try{
                commit('errorLogin', false)
                let res = await axios.post("/login", credentials)
                
                commit('setUser', res)
                return dispatch('getUser', res.data.token);
               
                
               
               
            }catch(error){
                commit('errorLogin',true)
                
            }
        },
        async getUser({commit}, token ){
            if (token) {
                commit('setToken', token);
            }
            try {
                
                let response = await axios.get('/user');
                commit('setUser', response.data.user);

            } catch (e) {
                commit('setToken', null);
                commit('setUser', null);
            }
        },
        async logout({commit}){
            let token = localStorage.getItem('token');
            await axios.get('/logout', token).then(() => {
                commit('setToken', null);
                commit('setUser', null);
            })
        }

    },
    modules:{

    }
})