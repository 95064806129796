import { createApp } from 'vue'
import App from './App.vue'
import router from '@/routes'
import Axios from 'axios'
import store from './store'
import moment from 'moment';
require('@/assets/fontawesome6/css/fontawesome.css')

//Axios.defaults.baseURL = process.env.VUE_APP_URL;
store.subscribe((mutation) => {
    switch(mutation.type){
        case 'setToken':
            if(mutation.payload){
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+mutation.payload;
                localStorage.setItem('token', mutation.payload);
            }else{
                
                Axios.defaults.headers.common['Authorization'] = null;
                localStorage.removeItem('token');
                
            }
            break;
    }
});


Axios.defaults.baseURL = process.env.VUE_APP_URL;
//Axios.defaults.baseURL = "http://172.30.0.4:8000/api";

const app = createApp(App).use(router).use(store)
app.config.globalProperties.$filters = {
    formatDate(value, format) {
        if (value) {
            if(format){
                if(typeof format == "string"){
                    return moment(String(value)).format(format)
                }
                return moment(String(value)).format('DD/MM/YYYY')
            }
      }
    }
}
app.mount('#app')
