import Axios from 'axios';
export default class Productos {
    static buscarPedidos(search, page){
        let params = {search: search, page: page}
        console.log('params', params)
        return new Promise((resolve, reject)=>{
            Axios.get('pedidos',{params:params}).then((e)=>{
                return resolve(e.data)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static getPedido(id){
        return new Promise((resolve, reject)=>{
            Axios.get('pedidos/'+id).then((e)=>{
                return resolve(e.data.pedido);
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static reenviarPedido(data){
        return new Promise((resolve, reject)=>{
            Axios.get('reenviarPedido', {params:data}).then((e)=>{
                return resolve(e.data);
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static descargarPedido(data){
        return new Promise((resolve, reject)=>{ 
            Axios.get('descargarPedido', {params:data, responseType: 'arraybuffer'}).then((e)=>{
                return resolve(e.data);
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    
    
}