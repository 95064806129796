<template>
    <div>
        <Loader/>
         <section >
            <div class="text-center pt-5">
            </div>
            <div class="hero-container text-center">
                <h1>Bienvenido</h1>
                    <div class="container wow fadeInUp btn-form pt-5 pb-4 col-lg-4">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 col-md-8">
                                <div class="form">
                                <form role="form" class="contactForm">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <input type="email" v-model="datos.email" class="form-control btn-form" name="email" id="email" placeholder="Ingrese su email..." data-rule="email" :class="enviado && v$.datos.email.$error ? 'is-invalid' : ''" />
                                            <div class="text-danger" v-if="enviado && v$.datos.email.$error">
                                                Debe ingresar un Nombre
                                            </div>
                                        </div>
                                            <input type="password" v-model="datos.password" name="pass" class="form-control btn-form" id="pass" placeholder="Ingrese su contraseña..." data-rule="minlen:4" :class="enviado && v$.datos.password.$error ? 'is-invalid' : ''"/>
                                            <div class="text-danger" v-if="enviado && v$.datos.password.$error">
                                                Debe ingresar su password
                                            </div>
                                    </div>
                                    <span class="text-danger" v-if="this.error">{{this.mensaje}}</span>
                                    <div class="text-center mt-3"><button class="btn btn-primary" type="button" @click="login">Iniciar Sesión</button></div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    </div>

</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useStore } from 'vuex'
import Loader from './layouts/Loader.vue'
export default {
    name: "Login",
    components: {Loader},

    setup () {
        const store = useStore();
        return { v$: useVuelidate(), store }
    },
    data(){
        return{
            datos: {
                email:"",
                password:""
            },
            error:false,
            enviado:false,
            mensaje:null
        }
    },
    validations(){
        return {
            datos:{
                email: {required},
                password: {required}
            }
        }
    },
    created(){
        require('bootstrap/dist/css/bootstrap.css');
        require('@/assets/style.css');
    },
    methods:{
        login() {        
            this.enviado = true;
            this.error = false
            this.v$.datos.$touch();
            if (this.v$.datos.$invalid) {
              return false
            }
            this.store.dispatch("login", this.datos).then(() => {
                let user = this.store.state.user
                console.log(user)    
                this.$router.push({name:"Home"})           
            }).catch((e) => {
                console.log(e)
                this.error = true;
                this.mensaje = "Error de inicio de sesión: Usuario o contraseña incorrecta";
                
            });
        },
    }
}
</script>