<template>
    <CardPrimary title="Clientes">
        <template v-slot:container>
            <CardSecondary title="Agregar Cliente" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="col-12 row">
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="cliente.nombre" placeholder="Nombre" class="form-control" :class="data.enviado && v$.cliente.nombre.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Nombre</label>
                            <div class="text-danger" v-if="data.enviado && v$.cliente.nombre.$error">
                                Debe ingresar un Nombre
                            </div>
                        </div>
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="cliente.apellido" placeholder="Apellido" class="form-control" :class="data.enviado && v$.cliente.apellido.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Apellido</label>
                            <div class="text-danger" v-if="data.enviado && v$.cliente.apellido.$error">
                                Debe ingresar un Apellido
                            </div>
                        </div>
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="cliente.cuit" placeholder="cuit" class="form-control" :class="data.enviado && v$.cliente.cuit.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">CUIT</label>
                            <div class="text-danger" v-if="data.enviado && v$.cliente.cuit.$error">
                                Debe ingresar un CUIT
                            </div>
                        </div>
                        <div class="form-floating form-group col-sm-6 col-12">
                            <input type="text" v-model="cliente.email" placeholder="Email" class="form-control" :class="data.enviado && v$.cliente.email.$error ? 'is-invalid' : ''">
                            <label for="floatingInput">Email</label>
                            <div class="text-danger" v-if="data.enviado && v$.cliente.email.$error">
                                Debe ingresar un Email
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-3 text-end">
                        <button class="btn btn-outline-primary" @click="guardar">Guardar</button>
                    </div>
                </template>
                <template v-slot:footer>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import Axios from 'axios';
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import Alert from '@/components/utils/alert'
export default {
    name:"ListarUsers",
    components:{
                CardPrimary,
                CardSecondary
    },
    setup () {
        const router = useRouter();
        
        let cliente = reactive({
                nombre: null,
                apellido: null,
                email: null,
                cuit: null          
        })
        let data = reactive({
                enviado: false,   
                error: false        
        })
        const v$ = useVuelidate();
        const guardar = ()=>{
            data.enviado = true;
            data.error = false
            v$.value.cliente.$touch();
            if (v$.value.cliente.$invalid) {
              return false
            }
            Axios.post('clientes', cliente).then((e)=>{
                if(e.data.success){
                    Alert.Success({text: `Se guardo el cliente con exito.`}).then(()=>{
                        router.push({name: 'ListarClientes'})
                    })
                }else{
                    Alert.Error({text: "Ocurrió un error al intentar guardar"})
                }
            }).catch(()=>{
                Alert.Error({text: "Ocurrió un error al intentar guardar"})
            })
        }

        return { v$, cliente, data, guardar }
    },
    validations(){
        return {
            cliente:{
                nombre: { required },
                apellido: { required },
                email: { required },
                cuit: { required }
            }
        } 
    }
}
</script>