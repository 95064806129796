<template>
    <div class="col-12 loader" v-if="requestsCount > 0">
        <div class="loader1 pt-4 pb-3 px-5">
            <div class="d-flex justify-content-center text-primary">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                </div>
            </div>
            <p class="">Loading...</p>
        </div>
     </div>
</template>
<script>
import Axios from 'axios';
import Swal from "sweetalert2";
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
    name:'Loader',
    setup(){
        const requestsCount = ref(0)
        const store = useStore();
        const router = useRouter();
        const loader = ()=>{
            Axios.interceptors.request.use((config) => {
               
                if(config.params){
                    if(config.params.select)
                        return config;
                }
                requestsCount.value++;
                return config;
            },(error) => {
                requestsCount.value--;
                return Promise.reject(error);
            }
            );
            Axios.interceptors.response.use((response) => {
                if(response.config.params){
                    if(response.config.params.select)
                        return response;
                }
                requestsCount.value--;
                return response;
            },
            (error) => {
                console.log('error que capta', error.response.data.message)
                if (error.response.status == 401) {
                    if (!store.state.errorLogin) {
                        if(error.response.data.message === 'Token not provided'){
                            store.commit('setToken', null);
                            store.commit('setUser', null);
                            localStorage.removeItem('token');
                            localStorage.removeItem('usr');
                            Swal.fire({
                                icon: 'error',
                                title: 'Opps',
                                text: "Se venció el tiempo de sessión. Por favor vuelva a loguearse.",}).then(()=>{
                                router.push("/login");
                            })
                        }
                    }
                    if (error.response.data.message) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Opps',
                            text: 'Error de inicio de sesión: Usuario o contraseña incorrecta'});
                        
                    }
                    
                    
                    

                }
                requestsCount.value--;
                return Promise.reject(error);
            }
            );
        }

        onMounted(()=>{
            loader();
        })
        return {requestsCount}
    }
}
</script>
<style scoped>
.loader{
    position: fixed;
    width: 100%!important;
    height: 100%;
    z-index: 200000;
    display: flex;
    background-color: #0000002e;
    top: 0;
}
.loader1{
    width: auto;
    height: auto;
    background-color: white;
    margin: auto;
    border-radius: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loader1 img{
    padding-top: 10%;
    width: 31%;
}
.loader1 p{
    text-align: center;
}
</style>