import Axios from 'axios';
export default class Lista{

    static Delete(cliente){
        return new Promise((resolve, reject)=>{
            Axios.delete('clientes/'+cliente.id).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}